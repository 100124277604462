/* =================== BUSCA ANUNCIOS =================== */
jQuery(document).ready(function ($) { 

    // Função que carrega as cidades com anúncios
    function carregaCidadesAnuncios() {
        var campo_estado = $('#busca_estado');
        var campo_cidade = $('#busca_cidade');
        var estado_id = campo_estado.val();
        if (estado_id) {
            var url = campo_cidade.data('url');
            var cidade_edit = campo_cidade.data('cidade');
            $.getJSON(url + "?estado_id=" + estado_id, function (data) {
                var cidades = data;
                if (cidades.length > 0) {
                    campo_cidade.html('');
                    campo_cidade.append('<option value="" hidden>Cidade</option>');
                    for (var i = 0, n = cidades.length; i < n; i++) {
                        var cidade = cidades[i];
                        if (cidade.id == cidade_edit) {
                            campo_cidade.append('<option value="' + cidade.id + '" selected>' + cidade.titulo + '</option>');
                        } else {
                            campo_cidade.append('<option value="' + cidade.id + '">' + cidade.titulo + '</option>');
                        }
                    }
                }
            });
        }
    }

    // Carrega automaticamente
    window.onload = function () {
        carregaCidadesAnuncios();
    };

    // Evento ao alterar um estado
    $('#busca_estado').on('change', function () {
        carregaCidadesAnuncios();
    });

});
