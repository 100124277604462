// (function($) {
//   'use strict'
  
//   // Lateral direita fixa ao dar scroll na página
//   const element = $('.anuncio-right-in');
//   const container = $('.anuncio-right');
//   const leftContent = $('.anuncio-left');
 
//   if (element.length > 0 && !isMobileX(1199)) {
  
//     const marginTop = $('.header').outerHeight();
    
//     const elementHeight = element.outerHeight();
//     const elementWidth = element.width();
//     const leftContentHeight = leftContent.outerHeight();
//     const leftContentOffsetTop = leftContent.offset().top;
//     const leftContentBottom = leftContentHeight + leftContentOffsetTop;
//     const elementOffsetTop = element.offset().top;
//     const elementOffsetLeft = element.offset().left;
//     const windowHeight = $(window).height();
    
//     element.css('max-width', elementWidth);

//     if (leftContentHeight > elementHeight) {
//       container.height(leftContentHeight);
//     }

//     $(window).on('scroll', function() {
//       const scrollTop = $(window).scrollTop();
//       if ((scrollTop + marginTop) > elementOffsetTop) {
//         element.css({
//           'position': 'fixed',
//           'top': marginTop,
//           'left': elementOffsetLeft,
//           'bottom': ''
//         });
//         if ((scrollTop + windowHeight) > leftContentBottom) {
//           element.css({
//             'position': 'absolute',
//             'top': '',
//             'left': '',
//             'bottom': 0
//           });
//         }
//       } else {
//         element.css({
//           'position': 'relative',
//           'top': '',
//           'left': '',
//           'bottom': ''
//         });
//       }
//     });
//   }

//   // Muda a posição da lateral (Mobile)
//   if (isMobileX(1199)) {
//     const container = $("#container-anuncio-reserva-mb");
//     const elemento = $('.anuncio-right-in');
//     elemento.appendTo(container);
//   }

// })(jQuery)
